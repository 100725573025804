import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { v1 as uuid } from 'uuid';

import colors from '../config/colors';

import pictoUploadCross from '../assets/img/picto_upload_cross.svg';
import pictoUploadCheck from '../assets/img/picto_upload_check.svg';
import pictoUploadDoc from '../assets/img/picto_upload_doc.svg';

const UnStyledFileUpload = ({ className, accept, label, onChange }) => {
    const inputFileId = uuid();

    const [file, setFile] = useState(null);
    useEffect(() => {
        return () => {
            setFile(null);
        };
    }, []);
    const handleChange = (evt) => {
        const { files } = evt.target;
        if (files && files.length > 0) {
            setFile(files[0]);
            onChange({ file: files[0] });
        }
    };

    const handleDelete = () => {
        setFile(null);
        onChange({ file: null });
    };

    return (
        <div className={className}>
            {label && <label>{label}</label>}
            {file && (
                <div className='details'>
                    <img src={pictoUploadCross} alt='' onClick={handleDelete} />
                    <img src={pictoUploadDoc} alt='' />
                    <span>{file.name}</span>
                    <img src={pictoUploadCheck} alt='' />
                </div>
            )}
            <div className='action'>
                <label htmlFor={inputFileId}>Joindre un fichier</label>
                <span className='material-icons-outlined'>file_upload</span>
                <input type='file' accept={accept} id={inputFileId} onChange={handleChange} />
            </div>
        </div>
    );
};

const StyledFileUpload = styled(UnStyledFileUpload)`
    > label {
        margin-bottom: 0.25rem;
        color: var(--color-input-label);
        font-size: 0.75rem;
    }

    > div {
        &.details {
            margin-bottom: 0.5rem;

            > span,
            > img {
                display: inline-block;
                margin-right: 0.5rem;
                font-size: 0.6rem;
            }

            > img {
                &:first-child {
                    transition: all 200ms ease-out;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.5;
                    }
                }
            }
        }

        &.action {
            display: flex;
            align-items: center;
            width: max-content;
            padding: var(--dimen-button-padding);
            border: 1px solid ${colors.primary};
            border-radius: var(--dimen-input-border-radius);
            color: ${colors.primary};
            font-weight: 500;
            transition: all 300ms ease-in-out;
            font-size: var(--input-size);
            margin: 0;
            :hover {
                background: ${colors.primary};
                color: ${colors.white};
            }
            > label {
                cursor: pointer;
                margin: 0 1rem 0 0;
            }

            > input[type='file'] {
                display: none;
            }
        }
    }
`;

const FileUpload = (props) => <StyledFileUpload {...props} />;

FileUpload.propTypes = {
    accept: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func
};

FileUpload.defaultProps = {
    accept: 'application/pdf',
    label: null,
    onChange: () => {}
};

export default FileUpload;
