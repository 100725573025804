import React from 'react';
import PropTypes from 'prop-types';

import { isNullish } from '../../utils';
import styleTableBody from './utils/style-table-body';
import { columnsPropType, dataSourcePropType } from './utils/default-prop-types';

import { SortingArrows } from '../sorting_arrrows';
import TableEmptyMessage from './TableEmptyMessage';

function UnStyledTableBody({ className, columns, currentItems, onSortAscend, onSortDescend, noDataMessage }) {
    if (currentItems.length === 0) return <TableEmptyMessage>{noDataMessage}</TableEmptyMessage>;
    return (
        <main className={className}>
            <table>
                <thead>
                    <tr>
                        {columns.map(({ key, title, dataIndex, format, sortable }) => (
                            <th key={key}>
                                <div className={key}>
                                    <span>{title}</span>
                                    {sortable && <SortingArrows onSortAscend={() => onSortAscend({ dataIndex, format })} onSortDescend={() => onSortDescend({ dataIndex, format })} />}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {currentItems.map((item, itemIndex) => (
                        <tr key={itemIndex}>
                            {columns.map((column, columnIndex) => (
                                <td key={getCellKey(column, columnIndex)}>{displayCellContent(column, item)}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </main>
    );
}

function getCellKey(column, columnIndex) {
    return [column.key, columnIndex].join('-');
}

function displayCellContent(column, item) {
    const { dataIndex, render } = column;
    if (isNullish(dataIndex) && isNullish(render)) throw new Error('You must provide either <dataIndex> or <render> for each column');

    const value = item[dataIndex];
    if (Object.keys(item).includes(dataIndex)) {
        return render ? render(value, item.key) : value;
    }

    return render(value, item.key);
}

const StyledTableBody = styleTableBody(UnStyledTableBody);

export function TableBody({ columns = [], currentItems = [], ...restProps }) {
    return <StyledTableBody columns={columns} currentItems={currentItems} {...restProps} />;
}
TableBody.propTypes = {
    columns: columnsPropType,
    currentItems: dataSourcePropType,
    onSortAscend: PropTypes.func.isRequired,
    onSortDescend: PropTypes.func.isRequired
};
