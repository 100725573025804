import { useState, useEffect } from 'react';

import { toLowerCase } from '../../../utils/text';

export default function (async, options, actionView, onChange, onSelect, onHide) {
    const [shown, setShown] = useState(false);
    const [selectedOption, setSelectedOption] = useState({});
    const [matchingOptions, setMatchingOptions] = useState([]);
    const [hasFocus, setHasFocus] = useState(false);

    function handleOptionClick(option) {
        setShown(false);
        setSelectedOption(option);
        if (onSelect) onSelect({ option });
        onHide();
    }

    function handleChange({ value }) {
        setShown(true);
        setHasFocus(true);
        if (async) {
            if (onChange) onChange({ value });
        } else {
            setMatchingOptions(sortAscending(filterMatchingOptions(options, value), value));
        }
    }

    function handleBlur() {
        setHasFocus(false);
        if (!selectedOption.label) setSelectedOption({});
    }

    function handleFocus() {
        if (async && actionView) setShown(true);
    }

    useEffect(() => {
        if (async) setMatchingOptions(options);
    }, [options]);

    return {
        shown,
        hasFocus,
        selectedOption,
        matchingOptions,
        setShown,
        handleOptionClick,
        handleChange,
        handleBlur,
        handleFocus
    };
}

function filterMatchingOptions(options, value) {
    return options.filter(({ label }) => toLowerCase(label).includes(toLowerCase(value)));
}

function sortAscending(options = [], value) {
    const lowerCaseValue = toLowerCase(value);

    return options.sort((currentOption, nextOption) => {
        const currentIndex = currentOption.label.toLowerCase().indexOf(lowerCaseValue);
        const nextIndex = nextOption.label.toLowerCase().indexOf(lowerCaseValue);
        if (currentIndex < nextIndex) return -1;
        if (currentIndex > nextIndex) return 1;

        return 0;
    });
}
