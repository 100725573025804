import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../../config/colors';
import { fontSizes } from '../../config/sizes';
import { toNonNullString } from '../../utils';

function UnStyledCheckbox({ className, testId, id, name, dataCy, label, defaultChecked, disabled, onChange }) {
    function handleChange(evt) {
        const { target } = evt;
        if (onChange) onChange({ label, name, value: target.checked });
    }

    return (
        <div className={className}>
            <input data-testid={testId} data-cy={dataCy} type='checkbox' id={id} name={name} disabled={disabled} defaultChecked={defaultChecked} onChange={handleChange} />
            <label htmlFor={id}>
                <span />
                <span>{label}</span>
            </label>
        </div>
    );
}

function UnStyledControlledCheckbox({ className, checked, testId, id, name, label, disabled, onChange }) {
    function handleChange(evt) {
        const { target } = evt;
        if (onChange) onChange({ label, name, value: target.checked });
    }

    return (
        <div className={className}>
            <input data-testid={testId} data-cy={toNonNullString(name + label)} type='checkbox' id={id} checked={checked || false} name={name} disabled={disabled} onChange={handleChange} />
            <label htmlFor={id}>
                <span />
                <span>{label}</span>
            </label>
        </div>
    );
}

const applyStyle = (C) => styled(C)`
    display: inline-block;
    width: 100%;
    position: relative;

    > input {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;

        &:checked + label > span:first-child {
            background: ${colors.primary};

            ::after {
                content: '';
                opacity: 1;
                transform: scale(1) translateY(-80%) translateX(-55%) rotate(-45deg);
            }
        }
    }

    > label {
        margin: 0;
        display: flex;
        cursor: pointer;
        font-size: ${fontSizes.sm};

        > span {
            :first-child {
                display: inline-block;
                width: 1.25rem;
                height: 1.25rem;
                border: 1px solid ${colors.input.border};
                border-radius: 5px;
                margin-right: 0.35rem;
                position: relative;
                background: white;
                transition: transform 300ms ease-in-out;

                &::after {
                    content: '';
                    position: absolute;
                    display: block;
                    top: 50%;
                    left: 50%;
                    transform: scale(0) translateY(-80%) translateX(-55%) rotate(-45deg);
                    width: 10px;
                    height: 5px;
                    border-color: ${colors.white};
                    border-width: 0 0 2px 2px;
                    border-style: solid;
                    transition: transform 150ms ease-in-out;
                }
            }

            :last-child {
                flex: 1;
                display: inline-block;
                padding-top: 2px;
                color: ${getLabelColor};
            }
        }
    }
`;
const StyledControlledCheckbox = applyStyle(UnStyledControlledCheckbox);
const StyledCheckbox = applyStyle(UnStyledCheckbox);

function getLabelColor({ styles = {} }) {
    const { labelColor = 'default' } = styles;

    return labelColor === 'default' ? colors.black : colors[labelColor];
}

export const ControlledCheckbox = ({ ...restProps }) => {
    return <StyledControlledCheckbox {...restProps} />;
};
export default function Checkbox({ defaultChecked = false, ...restProps }) {
    return <StyledCheckbox defaultChecked={defaultChecked} {...restProps} />;
}
Checkbox.propTypes = {
    label: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultChecked: PropTypes.bool
};
